import React, { useEffect, useState } from "react";
import axios from "axios";
import { createImage } from "../../utils";
import { apiRoutes } from "../../constants/apiRoutes";
import Layout from "../../components/Layout";
import FilmFestivalHeader from "../../components/FilmFestival/Header";
import HeroSection from "../../components/FilmFestival/HeroSection";
// import { Helmet } from "react-helmet";
import Helmet from "../../components/Helmet";
import routes from "../../components/constants/routes";

const Screenings = () => {
  const [screeningData, setScreeningData] = useState({});

  const fetchFilmScreenings = async () => {
    try {
      const { data } = await axios.get(apiRoutes.filmFestivalScreening);
      if (data) {
        setScreeningData(data.data.attributes);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchFilmScreenings();
  }, []);

  const seoData = {
    title: "Film Festival schedule | Exclusive Movie Experiences",
    description:
      "Explore our exclusive film schedule at Film Festivals Luxembourg. Immerse yourself in captivating movies from various genres. Book your tickets for a cinematic adventure.",
    url: `${process.env.GATSBY_SITE_URL}${routes.FFScreenings}/`,
  };

  return (
    <Layout>
      <Helmet
        title={seoData.title}
        description={seoData.description}
        url={seoData?.url}
      />

      <div className="film-festival">
        <div className="container-fluid padding-0">
          <div className="row">
            <div className="col-12 pr-mob-0">
              <HeroSection />
              <div className="head-tab mt-40">
                <FilmFestivalHeader />
                {Object.keys(screeningData).length ? (
                  <div className="tab-content" id="nav-tabContent">
                    <div className="film-screenings">
                      <div className="screenings">
                        <h2>{screeningData?.title}</h2>
                        <div className="screenings-details">
                          <img
                            src={createImage(
                              screeningData?.image?.data?.attributes?.url
                            )}
                            alt={
                              screeningData?.image?.data?.attributes
                                ?.alternativeText
                            }
                            width={"auto"}
                            height={"auto"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <p className="text-center" style={{ margin: "15em 0" }}></p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Screenings;
